.total-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0 0 80px 20px #000;
  -moz-box-shadow: 0 0 80px 20px #000;
  box-shadow: 0 0 80px 20px #000;
  background-color: #ffffff;
}
.container {
  position: relative;
  padding: 0;
}

.hidden {
  display: none !important;
}


// Media


@media (max-width: 1499px) {
  .total-container {
    width: 1170px;
  }
}
@media (max-width: 1199px) {
  .total-container {
    width: 970px;
  }
}
@media (max-width: 991px) {
  .total-container {
    width: 750px;
  }
}
@media (max-width: 767px) {
  .total-container {
    width: 100%;
  }
}
@media (min-width: 1500px) {
  .total-container {
    width: 1400px;
  }
  .container {
    width: 1000px;
  }
}