// Fonts

$mpb: 'MyriadProBold', sans-serif;
$pc: 'PragmaticaCondensed', sans-serif;
$rl: 'RobotoLight', sans-serif;
$rr: 'RobotoRegular', sans-serif;
$rb: 'RobotoBold', sans-serif;



// Colors

$grey: #666666;
$red: #db6a6a;
$blue: #5482ae;