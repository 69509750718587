@mixin placeholder($color: $white, $weight: 700) {
    ::-webkit-input-placeholder {
        color: $color;
        font-weight: $weight;
        text-transform: uppercase;
    }

    :-moz-placeholder {
        color: $color;
        font-weight: $weight;
        text-transform: uppercase;
    }

    :-ms-input-placeholder {
        color: $color;
        font-weight: $weight;
        text-transform: uppercase;
    }
}

@mixin transition($transition) {
  -webkit-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}
