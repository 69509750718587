.footer-wrap {
  @extend .header-wrap;
  background-color: transparent;
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
  min-height: 50px;
  color: $grey;
}
.footer-spoiler {
  display: flex;
  position: absolute;
  overflow: hidden;
  width: 100%;
  bottom: 100%;
  color: #ffffff;
  font-family: $rr;
  font-size: 16px;
  line-height: 20px;
  background-color: rgba(#000, .5);
  height: 50px;
}

@keyframes spoiler {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    left: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.spoiler-list {
  display: flex;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  left: 100%;
  -webkit-animation-name: spoiler;
  -moz-animation-name: spoiler;
  -o-animation-name: spoiler;
  animation-name: spoiler;
  -webkit-animation-duration: 30s;
  -moz-animation-duration: 30s;
  -o-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
  li {
    display: flex;
    white-space: pre;
    margin-top: auto;
    margin-bottom: auto;
    &::after {
      content: '';
      display: inline-block;
      width: 5px;
      height: 5px;
      background-color: #ffffff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      margin: auto 10px;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
.footer-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #ffffff;
  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;
  }
  img {
    max-height: 35px;
    max-width: 85px;
  }
}



// media



@media (max-width: 767px) {
  .footer-spoiler {
    height: 40px;
    font-size: 14px;
    line-height: 18px;
    width: 100vw;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}