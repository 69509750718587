// Hamburger

$hamburger-padding-x                       : 0;
$hamburger-padding-y                       : 5px;
$hamburger-layer-width                     : 25px;
$hamburger-layer-height                    : 4px;
$hamburger-layer-spacing                   : 4px;
$hamburger-hover-opacity                   : 1;

.hamburger {
  height: 31px;
  &:hover {
    .hamburger-inner {
      background-color: $red;
      &::before,
      &::after {
        background-color: $red;
      }
    }
  }
}