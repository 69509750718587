.debts-section {
  @extend .contacts-section;
}
.debts-container {
  @extend .contacts-container;
  h3 {
    display: flex;
    width: 100%;
    border: none;
    justify-content: center;
    align-items: center;
    &::before,
    &::after {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }
    &::before {
      margin-right: 15px;
      background-image: url(../img/svg/left-arrow.svg);
    }
    &::after {
      margin-left: 15px;
      background-image: url(../img/svg/right-arrow.svg);
    }
  }
}
.debts-content-block {
  p {
    margin-bottom: 20px;
  }
}