.header-wrap {
  @extend .container;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.header {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  min-height: 70px;
  color: $grey;
  a {
    color: $grey;
  }
  & > div {
    position: relative;
    display: flex;
  }
}

.header-logo-block {
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  img {
    max-height: 60px;
    max-width: 90px;
  }
}

.header-links-block {
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
  & > div {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
  }
}
.header-mail-wrap {
  a {
    margin: 5px 0;
    &::before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      vertical-align: -2px;
      margin-right: 5px;
      background-image: url(../img/svg/world.svg);
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }
  }
}
.header-tel-wrap {
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 1px;
    top: 50%;
    width: 1px;
    height: calc(100% - 20px);
    background-color: #d0d0d0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  a {
    margin: 5px 0;
    &::before {
      content: '';
      display: inline-block;
      width: 13px;
      height: 13px;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      vertical-align: -1px;
      margin-right: 5px;
    }
  }
}
.phone-link {
  &::before {
    background-image: url(../img/svg/phone.svg);
  }
}
.smartphone-link {
  &::before {
    background-image: url(../img/svg/smartphone.svg);
  }
}

.red-btn {
  @include transition(.2s);
  border: none;
  background-color: $blue;
  color: #ffffff !important;
  text-transform: uppercase;
  font-family: $rb;
  font-size: 16px;
  line-height: 20px;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0,0,0, .17);
  -moz-box-shadow: 5px 5px 10px 0 rgba(0,0,0, .17);
  box-shadow: 5px 5px 10px 0 rgba(0,0,0, .17);
  padding: 7px 9px;
  &:hover {
    text-decoration: none;
    background-color: $grey;
  }
  &:focus {
    text-decoration: none;
  }
}
.header-buttons-block {
  padding: 0 20px;
  align-items: center;
}
.callback-btn {
  @extend .red-btn;
  margin: 10px 0;
}
.hamburger {
  display: none;
  margin-left: 15px;
}

.head-menu {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: -28px;
}
.head-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  li {
    padding: 0 2px;
  }
  a {
    @include transition(.2s);
    color: #ffffff;
    padding: 5px 8px;
    background-color: rgba(#000, .5);
    &:hover, &:focus {
      text-decoration: none;
      background-color: $blue;
    }
  }
}



// Media



@media (max-width: 991px) {
  .header-logo-block {
    padding: 0 15px;
    img {
      max-height: 50px;
      max-width: 80px;
    }
  }
  .header-links-block {
    & > div {
      padding: 0 15px;
    }
  }
  .header-buttons-block {
    padding: 0 15px;
  }
  .red-btn {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 767px) {
  .header-logo-block {
    min-height: 60px;
  }
  .header-links-block {
    order: 3;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 5px 0;
  }
  .header-tel-wrap {
    &::before {
      display: none;
    }
  }
  .header-buttons-block {
    min-height: 60px;
    flex-grow: 1;
    justify-content: flex-end;
  }
  .red-btn {
    font-size: 12px;
    line-height: 16px;
  }
  .hamburger {
    display: inline-block;
  }
  .head-menu {
    display: none;
    position: relative;
    padding: 10px 0 5px;
    background-color: #ffffff;
    bottom: auto;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 50px;
      height: 1px;
      background-color: #d0d0d0;
      left: 50%;
      top: 4px;
      margin-left: -25px;
    }
  }
  .head-list {
    flex-direction: column;
    align-items: center;
    li {
      margin: 2px 0;
    }
    a {
      background-color: transparent;
      color: $grey;
      &:hover {
        color: #ffffff;
      }
    }
  }
}


@media (min-width: 768px) {
  .head-menu {
    //display: block !important;
  }
}