.section {
  display: flex;
  flex-grow: 1;
  position: relative;
  width: 100%;
}
.main-section {
  @extend .section;
}
.main-slider {
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  .slick-list {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    background-color: #ffffff;
  }
  .slick-track {
    position: relative;
    flex-grow: 1;
    height: 100%;
  }
  .slick-slide {
    display: flex;
  }
  & > .slick-dots {
    position: absolute;
    bottom: 0;
    left: -100%;
  }
}
.main-slide {
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 80px 0;
  background-color: #000000;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;
  color: #ffffff;
  .container {
    padding-left: 20px;
    & > * {
      width: 50%;
    }
  }
  h2 {
    margin-bottom: 20px;
  }
}
.slide-content-block {
  position: relative;
  background-color: rgba(#000, .5);
  padding: 30px 30px 30px 0;
  font-size: 16px;
  line-height: 20px;
  font-family: $rr;
  &::before {
    content: '';
    display: block;
    position: absolute;
    background-color: rgba(#000, .5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
.main-slider-dots {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;
  ul {
    height: 12px;
  }
  li {
    display: inline-block;
    padding: 3px;
    height: 12px;
  }
  .slick-active {
    button {
      background-color: $red;
    }
  }
  button {
    width: 14px;
    height: 6px;
    color: transparent;
    z-index: -10000px;
    border: none;
    background-color: #ffffff;
    padding: 0;
  }
}



// Media



@media (max-height: 600px) {
  .main-slider {
    .slick-track {
      height: auto;
    }
  }
}
@media (max-width: 991px) {
  .main-slide {
    .container {
      & > * {
        width: 70%;
      }
    }
  }
  .slide-content-block {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 767px) {
  .main-slide {
    .container {
      & > * {
        width: 95%;
      }
    }
  }
}
@media (max-width: 640px) {
  .main-slider {
    .slick-track {
      height: auto;
    }
  }
  .slide-content-block {
    font-size: 12px;
    line-height: 16px;
  }
}