.contacts-section {
  @extend .section;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;
  padding: 80px 0;
  color: #ffffff;
}

.contacts-container {
  @extend .container;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
  line-height: 20px;
  & > div {
    padding: 20px;
    background-color: rgba(#000, .5);
  }
  h3 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #fff;
  }
}
.address-list {
  padding: 18px 0;
  li {
    padding: 2px;
  }
}
.contacts-map-block {
  flex-grow: 1;
  margin-right: 20px;
  iframe {
    width: 100%;
    height: 250px;
  }
}
.contacts-form-block {
  width: 430px;
  margin-left: 20px;
  span {
    display: block;
    font-size: 12px;
    margin-top: 10px;
  }
}
.contacts-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $grey;
  font-family: $rr;
  font-size: 14px;
  padding: 15px 0;
  input {
    height: 33px;
  }
  textarea {
    height: 110px;
  }
  input, textarea {
    border: none;
    width: 100%;
    margin-bottom: 15px;
    padding: 3px 10px;
    &::-webkit-input-placeholder {
      opacity: 1;
    }
    &::-moz-placeholder          {
      opacity: 1;
    }
    &:-moz-placeholder           {
      opacity: 1;
    }
    &:-ms-input-placeholder      {
      opacity: 1;
    }
    &:focus::-webkit-input-placeholder {
      opacity: 0;
    }
    &:focus::-moz-placeholder          {
      opacity: 0;
    }
    &:focus:-moz-placeholder           {
      opacity: 0;
    }
    &:focus:-ms-input-placeholder      {
      opacity: 0;
    }
  }
  button {
    @extend .red-btn;
  }
  .requaire-input {
    padding-left: 15px;
    -webkit-background-size: 6px 6px;
    background-size: 6px 6px;
    background-image: url(../img/svg/form-star.svg);
    background-repeat: no-repeat;
    background-position: 7px 7px;
  }
}

.contacts-form2 {
  @extend .contacts-form;
  button {
    width: 160px;
  }
}

.file-input-wrap {
  position: relative;
  overflow: hidden;
  margin-right: auto;
}
.file-label {
  color: #ffffff;
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 25px;
  height: 22px;
  span {
    position: relative;
    z-index: 1;
    margin: 0;
    color: #ffffff;
    font-weight: normal;
    &::before {
      content: '';
      display: inline-block;
      height: 20px;
      width: 20px;
      vertical-align: -5px;
      margin-right: 5px;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      background-image: url(../img/svg/save-file.svg);
    }
  }
}
.file-input {
  position: absolute;
  top: -1000%;
  left: -1000%;
}

label.error {
  display: none !important;
}
input.error {
  background-color: $red + 90;
}



// Media



@media (max-width: 991px) {
  .contacts-container {
    font-size: 14px;
    line-height: 18px;
    & > div {
      padding: 20px;
      background-color: rgba(#000, .5);
    }
    h3 {
      padding-bottom: 15px;
      border-bottom: 1px solid #fff;
    }
  }
}
@media (max-width: 767px) {
  .contacts-section {
    padding-top: 40px;
  }
  .contacts-container {
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    & > div {
      width: 280px;
      padding: 20px;
      background-color: rgba(#000, .5);
    }
    h3 {
      padding-bottom: 15px;
      border-bottom: 1px solid #fff;
    }
  }
  .contacts-map-block {
    margin-right: 0;
    margin-bottom: 30px;
    iframe {
      height: 220px;
    }
  }
  .contacts-form-block {
    margin-left: 0;
  }
}