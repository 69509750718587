.clearfix {
    *zoom: 1;
    &:before,
    &:after {
        display: table;
        line-height: 0;
        content: "";
    }
    &:after {
        clear: both;
    }
}
