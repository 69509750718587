* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


html {
    position: relative;
    height: 100vh;
    overflow-x: hidden;
}

body {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    min-height: 100%;
    background-color: #2b2b2d;
    font-size: 14px;
    line-height: 18px;
}

a {
    display: inline-block;
    text-decoration: none;
    &:focus {
        outline: none;
    }
}

h1,h2,h3,h4,h5,h6 {
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
}

h2 {
    font-size: 30px;
    line-height: 34px;
    font-family: $mpb;
}

h3 {
    font-size: 27px;
    line-height: 32px;
    font-family: $mpb;
}

textarea,
input,
button {
    resize: none;
    outline: none;
}

.icon {
    display: inline-block;
}



// Media



@media (max-width: 991px) {
    body {
        font-size: 12px;
        line-height: 16px;
    }
    h2 {
        font-size: 26px;
        line-height: 30px;
    }
    h3 {
        font-size: 22px;
        line-height: 26px;
    }
}
@media (max-width: 767px) {
    h2 {
        font-size: 22px;
        line-height: 26px;
    }
    h3 {
        font-size: 16px;
        line-height: 20px;
    }
}
